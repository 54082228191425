import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PeriodSelectComponent } from './period-select/period-select.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PageDialogComponent } from './page-dialog/page-dialog.component';
import { TagComponent } from './tag/tag.component';
import { BadRequestComponent } from './bad-request/bad-request.component';

@NgModule({
  declarations: [
    LoaderComponent,
    DateRangeComponent,
    PeriodSelectComponent,
    NotFoundComponent,
    PageDialogComponent,
    TagComponent,
    BadRequestComponent
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    LoaderComponent,
    DateRangeComponent,
    PeriodSelectComponent,
    NotFoundComponent,
    TagComponent,
    BadRequestComponent
  ]
})
export class UiModule {}
