import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Assessment } from '../interfaces/Assessment';
import { CardList } from '../interfaces/CardList';
import { Order } from '../interfaces/Order';
import { Address } from '../interfaces/Address';
import { User } from '../interfaces/User';
import { Configuration } from '../interfaces/Configuration';
import { Patient } from '../interfaces/Patient';
import { Invite } from '../interfaces/Invite';
import { Terminal } from '../interfaces/Terminal';
import Stripe from 'stripe';
import { TerminalUser } from '../interfaces/TerminalUser';
import { FootScan } from '../interfaces/FootScan';
import { StaticAnalysis } from '../interfaces/StaticAnalysis';
import { DynamicAnalysis } from '../interfaces/DynamicAnalysis';
import { InsoleScan } from '../interfaces/InsoleScan';
import { Video } from '../interfaces/Video';
import { CameraConfig } from '../interfaces/CameraConfig';
import { ShoeBrand } from '../interfaces/ShoeBrand';
import { Shoe } from '../interfaces/Shoe';
import {
  AssessmentMonthlyCountResponse
} from '../../platform/admin/terminals-admin/terminals-admin-details/terminals-admin-details.component';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private API_URL: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  /**
   * ======================================================
   * ASSESSMENTS
   * ======================================================
   */

  getAssessments(canGetAllAssessments: boolean = false) {
    const path = canGetAllAssessments ? '/admin/assessments' : '/assessments';
    return this.http.get<Assessment[]>(`${this.API_URL}${path}`);
  }

  getAssessmentsForPatient(patientId: string) {
    return this.http.get<Assessment[]>(
      `${this.API_URL}/assessments/patients/${patientId}`
    );
  }

  getAssessmentDetails(assessmentId) {
    return this.http.get<Assessment>(
      `${this.API_URL}/assessments/${assessmentId}`
    );
  }

  getFootScanForAssessment(assessmentId: string) {
    return this.http.get<FootScan>(
      `${this.API_URL}/footscans/assessment/${assessmentId}`
    );
  }

  getStaticAnalysisForAssessment(assessmentId: string) {
    return this.http.get<StaticAnalysis>(
      `${this.API_URL}/staticanalysis/assessment/${assessmentId}`
    );
  }

  getDynamicAnalysisForAssessment(assessmentId: string) {
    return this.http.get<DynamicAnalysis>(
      `${this.API_URL}/dynamicanalysis/assessment/${assessmentId}`
    );
  }

  getVideoForAssessment(assessmentId: string) {
    return this.http.get<Video>(
      `${this.API_URL}/videos/assessment/${assessmentId}`
    );
  }

  updateAssessment(data, assessmentId: string) {
    return this.http.put(`${this.API_URL}/assessments/${assessmentId}`, data);
  }

  deleteAssessment(assessmentId: string) {
    return this.http.delete(`${this.API_URL}/assessments/${assessmentId}`);
  }

  sendReportToPatient(assessmentId: string, patientId: string) {
    return this.http.post(`${this.API_URL}/patient-reports`, {
      assessment: assessmentId,
      patient: patientId
    });
  }

  downloadPatientReport(assessmentId: string, patientId: string) {
    return this.http.post(`${this.API_URL}/patient-reports/download-link`, {
      assessment: assessmentId,
      patient: patientId
    });
  }

  /**
   * ======================================================
   * CONFIGURATIONS
   * ======================================================
   */

  getConfigurations(isAdmin: boolean = false) {
    const path = isAdmin ? '/admin/configurations' : '/configurations';
    return this.http.get<Configuration[]>(`${this.API_URL}${path}`);
  }

  getConfigurationDetails(configurationId) {
    return this.http.get<Configuration>(
      `${this.API_URL}/configurations/${configurationId}`
    );
  }

  getConfigurationsForAssessment(assessmentId) {
    return this.http.get<Configuration[]>(
      `${this.API_URL}/configurations/assessments/${assessmentId}`
    );
  }

  addConfiguration(configuration: Configuration) {
    return this.http.post(`${this.API_URL}/configurations/`, configuration);
  }

  updateConfiguration(configuration: Configuration) {
    return this.http.put(`${this.API_URL}/configurations/${configuration._id}`, configuration);
  }

  removeConfiguration(configuration: Configuration) {
    return this.http.delete(
      `${this.API_URL}/configurations/${configuration._id}`
    );
  }

  /**
   * ======================================================
   * CAMERA CONFIG
   * ======================================================
   */
  getCameraConfig(terminalDeviceId: string) {
    return this.http.get<CameraConfig>(
      `${this.API_URL}/cameraconfigs/${terminalDeviceId}`
    );
  }

  deleteCameraConfig(terminalDeviceId: string) {
    return this.http.delete<CameraConfig>(
      `${this.API_URL}/cameraconfigs/${terminalDeviceId}`
    );
  }

  /**
   * ======================================================
   * DASHBOARD
   * ======================================================
   */

  getDashboardData(start, end, period, user) {
    return this.http.get<any[]>(`${this.API_URL}/dashboard`, {
      params: { start, end, period, user }
    });
  }

  /**
   * ======================================================
   * INSOLE SCANS
   * ======================================================
   */
  getInsoleScanForConfiguration(configuration) {
    return this.http.get<InsoleScan>(
      `${this.API_URL}/insolescans/configuration/${configuration}`
    );
  }

  /**
   * ======================================================
   * INVITES
   * ======================================================
   */

  getInvites() {
    return this.http.get<Invite[]>(`${this.API_URL}/invites`);
  }

  createInvite(inviteData) {
    return this.http.post(`${this.API_URL}/invites`, inviteData, {});
  }

  removeInvite(invite: Invite) {
    return this.http.delete(`${this.API_URL}/invites/${invite._id}`);
  }

  /**
   * ======================================================
   * INVOICES
   * ======================================================
   */
  getInvoicesBySubscription(subscriptionId: string) {
    return this.http.get<Stripe.Invoice[]>(
      `${this.API_URL}/invoices/subscription/${subscriptionId}`
    );
  }

  /**
   * ======================================================
   * PATIENTS
   * ======================================================
   */

  getPatients() {
    return this.http.get<Patient[]>(`${this.API_URL}/patients`);
  }

  getPatientDetails(patientId) {
    return this.http.get<Patient>(`${this.API_URL}/patients/${patientId}`);
  }

  searchPatients(searchQuery: string) {
    const httpOptions = {
      params: new HttpParams().set('search', searchQuery)
    };
    return this.http.get<Patient[]>(`${this.API_URL}/patients`, httpOptions);
  }

  updatePatient(patient: Patient, patientId: string) {
    return this.http.put(`${this.API_URL}/patients/${patientId}`, patient);
  }

  deletePatient(patientId: string) {
    return this.http.delete(`${this.API_URL}/patients/${patientId}`);
  }

  /**
   * ======================================================
   * ORDERS
   * ======================================================
   */
  getOrders() {
    return this.http.get<Order[]>(`${this.API_URL}/orders`);
  }

  getOrderDetails(orderId: string) {
    return this.http.get<Order>(`${this.API_URL}/orders/${orderId}`);
  }

  getPromoDiscount(orderItems: Configuration[], promoCode: String) {
    return this.http.post(`${this.API_URL}/orders/promo`, {
      order_items: orderItems,
      promo_code: promoCode,
    }, {});
  }

  submitOrder(orderData: Order, promoCode: String) {
    return this.http.post(`${this.API_URL}/orders`, {
      order: orderData,
      promo_code: promoCode,
    }, {});
  }

  updateOrderStatus(orderId, status: { status: string }) {
    return this.http.put(`${this.API_URL}/orders/${orderId}`, status);
  }

  /**
   * ======================================================
   * PAYMENT INTENTS
   * ======================================================
   */
  getPaymentIntentById(paymentIntentId: string) {
    return this.http.get<Stripe.PaymentIntent>(
      `${this.API_URL}/paymentintents/${paymentIntentId}`
    );
  }

  /**
   * ======================================================
   * PRICES
   * ======================================================
   */

  getPrices() {
    return this.http.get<Stripe.ApiList<Stripe.Price>>(
      `${this.API_URL}/prices`
    );
  }

  getPriceForProduct() {
    return this.http.get<Stripe.Price>(`${this.API_URL}/price-details`);
  }

  /**
   * ======================================================
   * Shoe brands
   * ======================================================
   */
  getAllShoeBrands() {
    return this.http.get<ShoeBrand[]>(`${this.API_URL}/shoebrands`);
  }

  getAllShoes() {
    return this.http.get<Shoe[]>(`${this.API_URL}/shoes`);
  }

  approveShoeBrand(shoeBrandId: string, approved: boolean) {
    return this.http.patch<ShoeBrand>(
      `${this.API_URL}/shoebrands/${shoeBrandId}`,
      {
        approved
      }
    );
  }

  /**
   * ======================================================
   * SUBSCRIPTIONS
   * ======================================================
   */

  getSubscriptions() {
    return this.http.get<Stripe.Subscription[]>(
      `${this.API_URL}/subscriptions`
    );
  }

  getSubscriptionDetails(subscriptionId: string) {
    return this.http.get<Stripe.Subscription>(
      `${this.API_URL}/subscriptions/${subscriptionId}`
    );
  }

  createSubscription(subscriptionsData) {
    return this.http.post(
      `${this.API_URL}/subscriptions`,
      subscriptionsData,
      {}
    );
  }

  cancelSubscription(subscriptionId: string) {
    return this.http.delete(`${this.API_URL}/subscriptions/${subscriptionId}`);
  }

  cancelSchedule(scheduleId: string) {
    return this.http.delete(`${this.API_URL}/schedules/${scheduleId}`);
  }

  /**
   * ======================================================
   * TERMINALS
   * ======================================================
   */

  createTerminal(terminal: Terminal) {
    return this.http.post<Terminal>(
      `${this.API_URL}/admin/terminals`,
      terminal
    );
  }

  getTerminalDetails(terminalId: string) {
    return this.http.get<Terminal>(`${this.API_URL}/terminals/${terminalId}`);
  }

  getTerminals() {
    return this.http.get<Terminal[]>(`${this.API_URL}/terminals`);
  }

  getAllTerminals() {
    return this.http.get<Terminal[]>(`${this.API_URL}/admin/terminals`);
  }

  getTerminalsBySubscription(subscriptionId: string) {
    return this.http.get<Terminal[]>(
      `${this.API_URL}/terminals/subscription/${subscriptionId}`
    );
  }

  updateTerminal(terminal: Partial<Terminal>, terminalId: string) {
    return this.http.put(`${this.API_URL}/terminals/${terminalId}`, terminal);
  }

  updateTerminalName(terminal: Terminal, terminalId: string) {
    return this.http.patch(`${this.API_URL}/terminals/${terminalId}`, terminal);
  }

  removeTerminal(subscription: Stripe.Subscription, terminalId: string) {
    return this.http.put(`${this.API_URL}/subscriptions/${subscription.id}`, {
      subscriptionItemId: subscription.items.data[0].id,
      terminalId
    });
  }

  deleteTerminal(terminalId: string) {
    return this.http.delete(`${this.API_URL}/terminals/${terminalId}`);
  }

  getMonthlyAssessmentCount(terminalId: string) {
    return this.http.get<AssessmentMonthlyCountResponse>(`${this.API_URL}/assessments-monthly-count/${terminalId}`);
  }

  getMonthlyAssessmentCountsForUser() {
    return this.http.get<AssessmentMonthlyCountResponse>(`${this.API_URL}/assessments-monthly-count`);
  }

  /**
   * ======================================================
   * TERMINAL USERS
   * ======================================================
   */
  createTerminalUser(terminalUser: TerminalUser) {
    return this.http.post(`${this.API_URL}/terminalusers`, terminalUser, {});
  }

  getTerminalUsers() {
    return this.http.get<TerminalUser[]>(`${this.API_URL}/terminalusers`);
  }

  updateTerminalUser(userId, terminalUserData) {
    return this.http.put(
      `${this.API_URL}/terminalusers/${userId}`,
      terminalUserData,
      {}
    );
  }

  removeTerminalUser(terminalUser: TerminalUser) {
    return this.http.delete(
      `${this.API_URL}/terminalusers/${terminalUser._id}`
    );
  }

  /**
   * ======================================================
   * USERS
   * ======================================================
   */

  getUsers(params?) {
    return this.http.get<User[]>(`${this.API_URL}/users`, {
      params
    });
  }

  getUserDetails(userId) {
    return this.http.get<User>(`${this.API_URL}/users/${userId}`);
  }

  createCard(cardData, userId) {
    return this.http.post(
      `${this.API_URL}/users/${userId}/payments`,
      cardData,
      {}
    );
  }

  updateDefaultCardForUser(cardData, userId) {
    return this.http.patch(
      `${this.API_URL}/users/${userId}/payments`,
      cardData,
      {}
    );
  }

  updateUser(userData, userId) {
    return this.http.put(`${this.API_URL}/users/${userId}`, userData, {});
  }

  removeUser(user: User) {
    return this.http.delete(`${this.API_URL}/users/${user._id}`);
  }

  changeUserPassword(passwords, userId) {
    return this.http.put(
      `${this.API_URL}/users/${userId}/change-password`,
      passwords,
      {}
    );
  }

  registerStripeCustomer(userId) {
    return this.http.put(`${this.API_URL}/users/${userId}/payments`, {});
  }

  addDeliveryAddress(address: Address, user: User) {
    return this.http.post(
      `${this.API_URL}/users/${user._id}/shippingaddresses/`,
      address
    );
  }

  updateDefaultAddressForUser(addressId, userId) {
    return this.http.put(
      `${this.API_URL}/users/${userId}/shippingaddresses/${addressId}`,
      {}
    );
  }

  removeDeliveryAddress(addressId: string, user: User) {
    return this.http.delete(
      `${this.API_URL}/users/${user._id}/shippingaddresses/${addressId}`
    );
  }

  getCards(userId) {
    return this.http.get<CardList>(`${this.API_URL}/users/${userId}/payments`);
  }

  removeCard(cardId, user: User) {
    return this.http.delete(
      `${this.API_URL}/users/${user._id}/payments/${cardId}`
    );
  }
}
