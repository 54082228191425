import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { StripeService } from './@core/stripe/stripe.service';
import { AppState } from './app.state';

declare global {
  interface Window {
    elements;
    stripe;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'pedics-webapp';
  public stripe;

  constructor(
    private store: Store<AppState>,
    private stripeService: StripeService
  ) {}

  ngOnInit() {
    this.store.pipe(select('user' as any)).subscribe((user) => {
      if (user) {
        this.saveState(user);
      }
    });
  }

  ngAfterViewInit() {
    this.stripeService
      .setPublishableKey(environment.stripePublicKey)
      .then((stripe) => {
        window.stripe = stripe;
        window.elements = stripe.elements();
      });
  }

  saveState(user) {
    try {
      const serializedState = JSON.stringify(user);
      localStorage.setItem('user', serializedState);
    } catch (error) {
      console.log(error);
    }
  }
}
