import { Action } from '@ngrx/store';
import { ActionTypes } from '../actions/ui.actions';

export interface State {
  showSidebar: Boolean;
  showCheckout: Boolean;
}

export const initialState: State = {
  showSidebar: window.innerWidth > 768,
  showCheckout: false
};

export function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case ActionTypes.OpenSidebar:
      return {
        ...state,
        showSidebar: true
      };

    case ActionTypes.CloseSidebar:
      return {
        ...state,
        showSidebar: false
      };

    case ActionTypes.OpenCheckout:
      return {
        ...state,
        showCheckout: true
      };

    case ActionTypes.CloseCheckout:
      return {
        ...state,
        showCheckout: false
      };

    default:
      return state;
  }
}
