import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from 'src/environments/environment';

/**
 * SENTRY IMPLEMENTATION
 *
 * Logging errors to sentry.io
 */
@Injectable()
export class SentryService implements ErrorHandler {
  constructor() {}
  handleError(error) {
    // Throw error if in dev mode. Otherwise there would be no error shown in browser console
    // If not in dev mode, capture error to sentry.
    if (!environment.production) {
      console.error(error);
    } else {
      // const user = this.authService.getUserDetails();
      Sentry.init({
        dsn:
          'https://10153b6765af89feec9b6dd8cf1dce22@o4505749764440064.ingest.sentry.io/4505749860974592',
      });

      // if (user && user._id) {
      //   Sentry.configureScope(function (scope) {
      //     scope.setUser({
      //       id: user._id,
      //     });
      //   });
      // }
      Sentry.captureException(error.originalError || error);

      // This opens an sentry dialog where the user can enter some informations about what happened.
      // Can be useful in the future!
      // Sentry.showReportDialog({ eventId });
    }
  }
}
