import { Injectable } from '@angular/core';
import {
  EditablePressureVideo,
  PressureVideo
} from '@pedics-io/sensor-client-pressure-datatypes';
import { ComputedFrameGenerator } from '@pedics-io/sensor-client-pressure-tools';
import { createFrameSetDeSerializer } from '@pedics-io/sensor-client-footscan-persistence';
import { FrameSet } from '@pedics-io/sensor-client-footscan-datatypes';
import { createVideoDeSerializer } from '@pedics-io/sensor-client-pressure-persistence';
import { createEditableVideoFromVideo } from '@pedics-io/sensor-client-pressure-video-edit';
import { BehaviorSubject } from 'rxjs';
import {
  getVideoBorderAnalyzer,
  getVideoBorderCutter
} from '@pedics-io/sensor-client-pressure-video-edit';
import { FrameType } from 'src/app/@core/interfaces/FrameType';
import { OrderStatus } from 'src/app/@core/interfaces/Order';
import {
  TerminalStatus,
  UpdateChannel
} from 'src/app/@core/interfaces/Terminal';
import Stripe from 'stripe';

export const enum PlaybackSpeed {
  Quarter = 0.25,
  Half = 0.5,
  ThreeQuarters = 0.75,
  Full = 1
}

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private isVideoPlayingSubject = new BehaviorSubject(false);
  private videoProgressSubject = new BehaviorSubject(0);
  private videoFrameSubject = new BehaviorSubject(0);

  public isVideoPlaying = this.isVideoPlayingSubject.asObservable();
  public videoProgress = this.videoProgressSubject.asObservable();
  public videoFrame = this.videoFrameSubject.asObservable();

  constructor() {}

  public isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  dateComparator(propA, propB) {
    if (
      new Date(propA.createdAt).getTime() < new Date(propB.createdAt).getTime()
    ) {
      return -1;
    }
    if (
      new Date(propA.createdAt).getTime() > new Date(propB.createdAt).getTime()
    ) {
      return 1;
    }
  }

  isToday(dateToCheck): boolean {
    const today = new Date();
    const anyDate = new Date(dateToCheck);
    return (
      anyDate.getDate() === today.getDate() &&
      anyDate.getMonth() === today.getMonth() &&
      anyDate.getFullYear() === today.getFullYear()
    );
  }

  generateRandomHash(length: number): String {
    let text: String = '';
    const possible: String =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  stringToArrayBuffer(string: string): ArrayBuffer {
    return new Uint8Array(string.split('').map((c) => c.charCodeAt(0))).buffer;
  }

  ArrayBufferToString(arrayBuffer: ArrayBuffer) {
    return new Uint8Array(arrayBuffer).reduce(
      (p, c) => p + String.fromCharCode(c),
      ''
    );
  }

  /**
   *
   * @param video - is an editable video (this means it contains the methods to insert and removes frames)
   *
   * Gets a recorded video and inserts the max pressure frame to the beginning of it.
   * So we can display the first frame as "poster" in the canvas
   */
  addMaxPressureFrame(video: EditablePressureVideo): PressureVideo {
    const maxFrame = ComputedFrameGenerator.generateMaxValueFrame(video);
    video.insertFrames(0, [maxFrame]);

    return video;
  }

  /**
   *
   * @param video - is an editable video (this means it contains the methods to insert and removes frames)
   *
   * Gets a recorded video and inserts the avaerage pressure frame to the end of it.
   * So we can display the first frame as "poster" in the canvas
   */
  addAveragePressureFrame(video: EditablePressureVideo): PressureVideo {
    const averageFrame = ComputedFrameGenerator.generateAverageFrame(video);
    const frameCount = video.getDescription().frameCount;

    video.insertFrames(frameCount, [averageFrame]);
    this.videoProgressSubject.next(100);

    return video;
  }

  public getDeserializedFrameSet(serializedFrameSet: ArrayBuffer): FrameSet {
    const frameSetDeSerializer = createFrameSetDeSerializer();

    return frameSetDeSerializer.deserializeFrameSet(serializedFrameSet);
  }

  public getDeserializedVideo(
    serializedVideo: ArrayBuffer
  ): EditablePressureVideo {
    const videoDeSerializer = createVideoDeSerializer();
    const deserializedVideo =
      videoDeSerializer.deserializeVideo(serializedVideo);
    return createEditableVideoFromVideo(deserializedVideo);
  }

  /**
   * =====================
   * PRESSURE VIDEOS
   * =====================
   */

  /**
   *
   * @param video - is an editable video (this means it contains the methods to insert and remove frames)
   *
   * Expects a recorded video and inserts the average OR max pressure frame to the end of it.
   * This allows us to display a poster frame for every video.
   */
  addVideoPoster(
    video: EditablePressureVideo,
    frameType: FrameType
  ): EditablePressureVideo {
    // Depending on the frameType param, get either the max or average frame value
    const posterFrame =
      frameType === FrameType.Max_Pressure
        ? ComputedFrameGenerator.generateMaxValueFrame(video)
        : ComputedFrameGenerator.generateAverageFrame(video);

    // Get the total amount of frames in the video.
    const frameCount = video.getDescription().frameCount;

    // Insert the max or average frame at the end of the video and set the progress to 1
    video.insertFrames(frameCount, [posterFrame]);
    this.videoProgressSubject.next(1);

    return video;
  }

  public setVideoProgress(progress: number): void {
    this.videoProgressSubject.next(progress);
  }

  public setVideoFrame(frame: number) {
    this.videoFrameSubject.next(frame);
  }

  /**
   *
   * @param status
   *
   * Changes and emits the current recording status.
   */
  public changeVideoStatus(status: boolean) {
    this.isVideoPlayingSubject.next(status);
  }

  /**
   *
   * @param video
   *
   * Resizes the pressure video to use as much space in the canvas as possible
   */
  public resizeVideoToFeet(
    video: EditablePressureVideo
  ): EditablePressureVideo {
    const analyzer = getVideoBorderAnalyzer();
    const borders = analyzer.getUnusedBorderArea(video, 9999, 0.1);
    const videoBorderData = analyzer.getMaxBalancedCutValues(borders, 2);

    const cutter = getVideoBorderCutter();
    const cutVideo = cutter.cutOffFromVideo(video, videoBorderData);

    return cutVideo;
  }

  /**
   *
   * @param orderStatus
   * @returns
   *
   * Returns a hex color depending on the passed order status
   */
  public getOrderStatusColor(orderStatus: OrderStatus): string {
    switch (orderStatus) {
      case OrderStatus.OPEN:
        return '#fdc13b';
      case OrderStatus.PENDING:
        return '#fb5bc2';
      case OrderStatus.PRODUCTION:
        return '#59c7d9';
      case OrderStatus.PAYMENT_PENDING:
        return '#ff443a';
      case OrderStatus.PAYMENT_FAILED:
        return '#ff443a';
      case OrderStatus.CANCELLED:
        return '#ff443a';
      case OrderStatus.SHIPPED:
        return '#658d94';
      case OrderStatus.COMPLETED:
        return '#58de62';
    }
  }

  /**
   *
   * @param orderStatus
   * @returns
   *
   * Returns a hex color depending on the passed terminal status
   */
  public getTerminalStatusColor(terminalStatus: TerminalStatus): string {
    switch (terminalStatus) {
      case TerminalStatus.CREATED:
        return '#fdc13b';
      case TerminalStatus.CANCELED_BY_USER:
        return '#fb5bc2';
      case TerminalStatus.IN_USE:
        return '#58de62';
      case TerminalStatus.SUBSCRIPTION_CANCELED:
        return '#a54d86';
      case TerminalStatus.SUBSCRIPTION_PAST_DUE:
        return '#861c61';
    }
  }

  /**
   *
   * @param orderStatus
   * @returns
   *
   * Returns a hex color depending on the passed terminal status
   */
  public getUpdateChannelColor(updateChannel: UpdateChannel): string {
    switch (updateChannel) {
      case UpdateChannel.ALPHA:
        return '#616365';
      case UpdateChannel.BETA:
        return '#b6bcbf';
      case UpdateChannel.STABLE:
        return '#50c7ff';
    }
  }

  /**
   *
   * @param orderStatus
   * @returns
   *
   * Returns a hex color depending on the passed terminal status
   */
  public getSubscriptionStatusColor(
    subscriptionStatus: Stripe.Subscription.Status
  ): string {
    switch (subscriptionStatus) {
      case 'active':
        return '#58de62';
      case 'canceled':
        return '#861c61';
      case 'incomplete':
        return '#a54d86';
      case 'incomplete_expired':
        return '#a54d86';
      case 'past_due':
        return '#a54d86';
      case 'trialing':
        return '#b6bcbf';
      case 'unpaid':
        return '#ff0000';
    }
  }

  /**
   *
   * @param playbackSpeed
   * @returns
   *
   * Walk through a list of possible playback speeds.
   * Depending on the given playbackSpeed, return the next
   * possible speed.
   */
  public switchPlaybackSpeed(playbackSpeed: number): number {
    // Define all available playback speeds to walk through
    const availablePlaybackSpeeds = [
      PlaybackSpeed.Quarter,
      PlaybackSpeed.Half,
      PlaybackSpeed.ThreeQuarters,
      PlaybackSpeed.Full
    ];

    // Find the currently set playback speed index
    const nextSpeedIndex = availablePlaybackSpeeds.findIndex(
      (item) => item === playbackSpeed
    );

    // Depending on the current playback speed index,
    // go to the next item in the speed array. If last
    // item is reached, start over with first again.
    const nextSpeed =
      nextSpeedIndex < 3
        ? availablePlaybackSpeeds[nextSpeedIndex + 1]
        : availablePlaybackSpeeds[0];

    return nextSpeed;
  }
}
