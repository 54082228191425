import { Action } from '@ngrx/store';

export enum ActionTypes {
  OpenSidebar = '[UI] Open sidebar',
  CloseSidebar = '[UI] Close sidebar',
  OpenCheckout = '[UI] Open checkout',
  CloseCheckout = '[UI] Close checkout'
}

export class OpenSidebar implements Action {
  readonly type = ActionTypes.OpenSidebar;
}

export class CloseSidebar implements Action {
  readonly type = ActionTypes.CloseSidebar;
}

export class OpenCheckout implements Action {
  readonly type = ActionTypes.OpenCheckout;
}

export class CloseCheckout implements Action {
  readonly type = ActionTypes.CloseCheckout;
}
