import { Injectable } from '@angular/core';
import { Stripe, StripeFactory } from './stripe.types';

const STRIPE_API_URL = 'https://js.stripe.com/v3/';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private _stripe: StripeFactory = window['Stripe'];
  private stripePromise: Promise<any>;

  constructor() {
    this.stripePromise = this.inject();
  }

  get stripe() {
    return this._stripe;
  }
  set stripe(s: StripeFactory) {
    this._stripe = s;
  }

  setPublishableKey(key: string, options?: any): Promise<Stripe> {
    return this.stripePromise.then(() => {
      return this.stripe(key, options);
    });
  }

  inject(): Promise<StripeFactory> {
    if (this.stripe) {
      return Promise.resolve(this.stripe);
    }

    return new Promise((res, rej) => {
      const head = this.getHeadElement();
      const script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('src', STRIPE_API_URL);
      head.appendChild(script);
      script.addEventListener('load', () => {
        this.stripe = window['Stripe'];
        res(this.stripe);
      });
    });
  }

  /**
   * Returns the `head` element.
   * @throws Error('Application does not have a head element');
   */
  getHeadElement() {
    let elm: HTMLElement = document.getElementsByTagName('head')[0];

    if (!elm) {
      throw new Error('Application does not have a head element');
    }
    return elm;
  }
}
