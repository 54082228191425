<div class="wrapper">
  <div class="left">
    <div class="not-found">404</div>
  </div>
  <div class="right">
    <div class="content">
      <div class="logo">
        <svg viewBox="0 0 1210 390" xmlns="http://www.w3.org/2000/svg">
          <title>pedics logo</title>
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(-16 -3)" fill="#E8E8E8">
              <rect
                transform="rotate(26 131.501 144.623)"
                x="42.501"
                y="27.123"
                width="178"
                height="235"
                rx="49"
              />
              <rect
                transform="rotate(26 272.545 289.436)"
                x="202.045"
                y="190.936"
                width="141"
                height="197"
                rx="49"
              />
            </g>
            <g transform="translate(24 -16)" fill="#58C7D9">
              <rect
                transform="rotate(26 131.501 144.623)"
                x="42.501"
                y="27.123"
                width="178"
                height="235"
                rx="49"
              />
              <rect
                transform="rotate(26 272.545 289.436)"
                x="202.045"
                y="190.936"
                width="141"
                height="197"
                rx="49"
              />
            </g>
            <path
              d="M530.325 202.95c5.1 0 7.65-2.21 7.65-6.63v-33.49c0-4.533-2.55-6.8-7.65-6.8h-52.53c-5.213 0-7.82 2.267-7.82 6.8v40.12h60.35zm-93.5 61.03V163c0-22.213 13.657-33.32 40.97-33.32h52.53c27.313 0 40.97 11.107 40.97 33.32v34.68c0 22.213-13.657 33.32-40.97 33.32h-60.35v32.98h-33.15zm279.65-61.03V231h-86.7c-25.387 0-38.08-10.257-38.08-30.77V157.9c0-18.813 12.693-28.22 38.08-28.22h62.22c17.453 0 26.18 7.877 26.18 23.63v14.45c0 7.593-2.153 13.175-6.46 16.745-4.307 3.57-10.88 5.355-19.72 5.355h-66.98v6.46c0 4.42 2.55 6.63 7.65 6.63h83.81zm-83.81-46.92c-5.1 0-7.65 2.437-7.65 7.31v6.97h53.38c4.307 0 6.46-2.493 6.46-7.48 0-4.533-2.153-6.8-6.46-6.8h-45.73zm146.88 0c-5.1 0-7.65 2.21-7.65 6.63v33.49c0 4.533 2.55 6.8 7.65 6.8h52.53c5.213 0 7.82-2.267 7.82-6.8v-40.12h-60.35zm93.5-64.43v106.08c0 22.213-13.657 33.32-40.97 33.32h-52.53c-27.313 0-40.97-11.107-40.97-33.32V163c0-22.213 13.657-33.32 40.97-33.32h60.35V91.6h33.15zm20.57 38.08h33.15V231h-33.15V129.68zm-.17-38.08h33.32v21.42h-33.32V91.6zm171.7 111.35V231h-77.01c-27.313 0-40.97-11.107-40.97-33.32V163c0-22.213 13.657-33.32 40.97-33.32h76.33v26.35h-76.33c-5.1 0-7.65 2.21-7.65 6.63v33.49c0 4.533 2.55 6.8 7.65 6.8h77.01zm118.66-34.85c17.453 0 26.18 6.97 26.18 20.91v19.38c0 15.073-8.727 22.61-26.18 22.61h-100.47v-28.05h86.87c4.307 0 6.46-2.437 6.46-7.31 0-4.08-2.153-6.12-6.46-6.12h-61.88c-17.453 0-26.18-7.083-26.18-21.25v-16.49c0-14.733 8.727-22.1 26.18-22.1h98.6v26.35h-85c-4.307 0-6.46 2.04-6.46 6.12 0 3.967 2.153 5.95 6.46 5.95h61.88z"
              fill="#4e4e4e"
            />
          </g>
        </svg>
      </div>
      <h1>We're sorry</h1>
      <p>The page you are looking for could not be found!</p>
      <a href="/" class="btn btn--primary">Go back to platform</a>
    </div>
  </div>
</div>
