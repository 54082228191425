import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Credentials } from '../@core/interfaces/Credentials';
import { User } from '../@core/interfaces/User';
import { Store, select } from '@ngrx/store';
import { AppState } from '../app.state';
import * as UserActions from '../platform/actions/user.actions';
import { SharedService } from '../platform/shared/shared.service';
import { take } from 'rxjs/operators';
import { ApiService } from '../@core/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private API_URL: String = environment.apiUrl;
  private redirectUrl: string | null;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private sharedService: SharedService,
    private api: ApiService
  ) {}

  login(credentials: Credentials) {
    return this.http.post(`${this.API_URL}/auth`, credentials);
  }

  registration(user: User) {
    return this.http.post(`${this.API_URL}/users`, user);
  }

  verifyEmail(token: string) {
    return this.http.post(
      `${this.API_URL}/users/${this.sharedService.generateRandomHash(
        20
      )}/verify`,
      { token }
    );
  }

  verifyInviteToken(token: string) {
    return this.http.get(`${this.API_URL}/invites/token/${token}`);
  }

  resendVerification(email: string) {
    return this.http.post(
      `${this.API_URL}/users/${this.sharedService.generateRandomHash(
        20
      )}/verify/resend`,
      email
    );
  }

  sendPasswordRecovery(email: string) {
    return this.http.post(
      `${this.API_URL}/users/${this.sharedService.generateRandomHash(
        20
      )}/recover-password`,
      email
    );
  }

  changePassword(password: { password: string; token: string }) {
    return this.http.post(
      `${this.API_URL}/users/${this.sharedService.generateRandomHash(
        20
      )}/recover-password/change`,
      password
    );
  }

  setToken(token: string) {
    window.sessionStorage.setItem('token', token);
  }

  setUserDetails(user: User) {
    this.store.dispatch(new UserActions.SetUser(user));
  }

  getToken(): string {
    const token: string = window.sessionStorage.getItem('token');
    return token;
  }

  getUserDetails(): User {
    const user: User = JSON.parse(localStorage.getItem('user'));

    return user;
  }

  removeAuthentication() {
    this.store.dispatch(new UserActions.SetUser(null));
    this.setRedirectUrl(null);
    sessionStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  isAuthenticated(): boolean {
    return this.getToken() ? true : false;
  }

  logout() {
    return this.http.delete(`${this.API_URL}/auth`);
  }

  public setRedirectUrl(redirectUrl: string) {
    this.redirectUrl = redirectUrl;
  }

  public getRedirectUrl(): string {
    return this.redirectUrl;
  }

  public updateUserDetails() {
    this.getUserIdFromStore();
  }

  private getUserIdFromStore(): void {
    this.store.pipe(take(1), select('user' as any)).subscribe((user: User) => {
      this.getAndUpdateUserDetails(user._id);
    });
  }

  private getAndUpdateUserDetails(userId: string) {
    this.api.getUserDetails(userId).subscribe((user: User) => {
      this.store.dispatch(new UserActions.SetUser(user));
    });
  }
}
